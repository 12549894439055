<template>
<div>
    <page-header
      :title="'Investors'"
      :sub-heading="true"
      :sub-header="'Dashboard / Investors'"
      :link-name="'Create Investor'"
      :link-url="'/investors/create'"
    >
    </page-header>
<!-- Investment popup -->


    <full-page-content-container>

      <ca-popup
        :header="open.header"
        :use-close-action="false"
        :toggle="open.popup"
        @close="clear()"
        v-show="open.data != null"
        class="investor__popup"
      >
        <template v-slot:content>
          <div class="po__container">
          <div class="row" v-for="(criterial, index) in open.data" :key="index">
            <div class="check-items">
              <input type="checkbox" name="" id="" @change="add(index)" :checked="investors[open.criteria].includes(index)? true: false"> 
              <span>
               {{criterial}}
              </span>
            </div>
          </div>
          </div>
        </template>

      </ca-popup>

    <div class="row">
    <form class="form col-xs-12">
      <div class="form__group">
        <label for="company" class="form__label">Company Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Caena"
          id="company"
          v-model="investors.company"
          name="company"
        />
      </div>

      <div class="form__group">
        <label for="lead_deals" class="form__label">lead Investor</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="lead_deals"
          v-model="investors.lead_deals"
          name="lead_deals"
      />
      </div>

      <div class="form__group">
        <label for="hq_location" class="form__label">Headquarter Location</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g London"
          id="hq_location"
          v-model="investors.hq_location"
          name="hq_location"
      />
      </div>

      <div class="form__group">
        <label for="contact_email" class="form__label">Contact Email</label>
        <input
          type="email"
          class="form__input"
          placeholder="e.g Johnathan@caena.io"
          id="contact_email"
          v-model="investors.contact_email"
          name="contact_email"
      />
      </div>

      <div class="form__group">
        <label for="contact_password" class="form__label">Contact Password</label>
        <input
          type="password"
          class="form__input"
          placeholder=" password"
          id="contact_password"
          v-model="investors.contact_password"
          name="contact_password"
      />
      </div>

      <div class="form__group">
        <label for="firstName" class="form__label">Contact Name</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g Johnathan"
          id="firstName"
          v-model="investors.contact_name"
          name="first_name"
        />
      </div>


      <div class="form__group">
        <label for="firstName" class="form__label">Contact Number</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g +44 7123456789"
          id="firstName"
          v-model="investors.contact_number"
          name="first_name"
        />
      </div>

      <div class="form__group">
        <label for="website" class="form__label">Website</label>
        <input
          type="text"
          class="form__input"
          placeholder="e.g https://caena.io"
          id="website"
          v-model="investors.website"
          name="website"
        />
      </div>

      <div class="investment__group">
        <div class="form__header">
        Investment Stage
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investment_stage" :key="index">
          {{list.investment_stage[stage]}}
            
            <span class="cancel" @click="remove(index, 'investment_stage')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group" >
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investment_stage,'Investment Stage', 'investment_stage'  )">
            Add
          </ca-button>
         </div>
      </div>
      
      <div class="investment__group">
        <div class="form__header">
        Startup Stage
        </div>
        <div class="items">
        <span class="selected" v-for="(stage, index) in investors.startup_stage" :key="index">
          {{list.startup_stage[stage]}}
            
             <span class="cancel" @click="remove(index, 'startup_stage')"> 
              x
            </span>
          </span>
        </div>
      
        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.startup_stage,'Startup Stage', 'startup_stage'  )">
            Add
          </ca-button>
        </div>
      </div>
      <div class="investment__group">
        <div class="form__header">
              Industries
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.sectors" :key="index">
          {{list.industries[stage]}}
             <span class="cancel" @click="remove(index, 'sectors')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'"  @click.native="openPopup(list.industries,'Industries', 'sectors'  )">
            Add
          </ca-button>
        </div>
        
       
      </div>

            <!-- <div class="investment__group">
        <div class="form__header">
              Countries of Investment
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.countries" :key="index" >
            {{list.countries.name[stage]}}
             <span class="cancel" @click="remove(index, 'investment_stage')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.countries,'Countries of investment', 'countries'  )">
            Add
          </ca-button>
        </div>
        
       
      </div> -->

      <div class="investment__group">
        <div class="form__header">
        Investor Type
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investor_type" :key="index">
          {{list.investor_type[stage]}}
             <span class="cancel" @click="remove(index, 'investor_type')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investor_type,'Investor type', 'investor_type'  )">>
            Add
          </ca-button>
        </div>
        
       
      </div>

      <div class="investment__group">
        <div class="form__header">
       Investment Type
        </div>
        <div class="items">
          <span class="selected" v-for="(stage, index) in investors.investment_type" :key="index">>
            {{list.investment_type[stage]}}
             <span class="cancel" @click="remove(index, 'investment_type')"> 
              x
            </span>
          </span>
        </div>

        <div class="form__group">
          <ca-button class="ghost" :type="'ghost'" :size="'small'" :icon="'plus'" @click.native="openPopup(list.investment_type,'Investment type', 'investment_type'  )">
            Add
          </ca-button>
        </div>
        
       
      </div>

      



      <div class="form__group">
        <ca-button @click.native="AddInvestors" :disabled="loading">
          <span>Save Changes</span>
          <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>
        </ca-button>
      </div>
    </form>
    </div>
    </full-page-content-container>
</div>
</template>

<script>
import CaButton from "../../components/buttons/CaButton";
import PageHeader from "../../layouts/components/fullPage/PageHeader";
import axios from "@/axios";
import FullPageContentContainer from '../../layouts/components/fullPage/FullPageContentContainer.vue';
import CaPopup from "../../components/popup/CaPopup";

export default {
  name: "CreateUser",
  components: { CaButton, FullPageContentContainer, PageHeader,CaPopup },
  data() {
    return {
      loading: false,
      investors: {
        company:'',
        lead_deals: "",
        hq_location: "",
        investment_stage:[],
        startup_stage: [],
        sectors:[],
        countries:[],
        investor_type:[],
        investment_type:[],
        minimum_investment: "",
        maximum_investment: "",
        contact_name: "",
        contact_number: "",
        contact_info: "",
        website: "",
        EIS_VCT: "",
        Notes: "",
        currency: "",
        geography: [],
        contact_email:'',
        contact_password:"",
        
      },
      investmentStage:false,
      open:{
        popup:false,
        data:null,
        header:'',
        criteria:''
      },
      list:
      {
        startup_stage          : this.$_LIST_STARTUP_STAGE          ,
        distribution           : this.$_LIST_SALES_AND_DISTRIBUTION ,
        investment_stage       : this.$_LIST_INVESTMEMT_STAGE       ,
        investor_type          : this.$_LIST_INVESTOR_TYPE          ,
        investment_type        : this.$_LIST_INVESTMENT_TYPE        ,
        shareholders           : this.$_LIST_SHAREHOLDERS           ,
        industries             : this.parseArray(this.$_LIST_COMPANY_TYPE,'value','text'),
        countries              : this.parseArray(this.$_LIST_COUNTRIES, 'code' ,'name'),
      },
      investment_stage:{},
      startup_stages:{
        idea: 'Idea',
        mvp: "MVP",
        scaling: 'Scaling',
        product_lunched: "Product Lunched",
        revenue_generated: "revenue Generated"
      },

    };
  },
  methods: {

    validation(){
      if (this.investors.investment_stage.length > 1) {
        this.$toast.error("Please select only one investment stage");
        return false;
      }

      if (this.investors.startup_stage.length > 2) {
        this.$toast.error("Please select only one startup stage");
        return false;
      }

      return true;
        
      
    },
    AddInvestors() {
      this.loading = true;
      if(this.validation()){
        axios
          .post(
            `${this.$store.state.server.requestUri}/admin/investor/create-account`,
            this.investors
          )
          .then((res) => {
            this.$toast("User created successfully", {
              position: "top-center",
              type: "success",
            });
            this.loading = false;
      
            console.log(res);
          })
          .catch((err) => {
            this.loading = false;
            this.$toast("Error creating Investor", {
              position: "top-center",
              type: "error",
            });
            console.log(err);
          });
      }
    },

    add(index){
      if (this.investors[this.open.criteria].includes(index)) {
        const position = this.investors[this.open.criteria].indexOf(index)
       this.investors[this.open.criteria].splice(position, 1);

      }else{
        if (this.open.criteria == 'investment_stage' && this.investors.investment_stage.length == 1) {
          this.$toast.error("Please select only one investment stage");
          return false;
        }
          
        if (this.open.criteria == 'startup_stage' && this.investors.startup_stage.length == 2) {
          this.$toast.error("Please select only two startup stage");
          return false;
        }
        this.investors[this.open.criteria].push(index)
      }
      

    },

    clear(){
      this.open.popup = false;
      this.open.data = null;
      this.open.header = '';
      this.open.criteria = '';
    },

    parseArray  ( a, b, c, d = {} )                       /*  */
    {
      for ( var e in a )
      {
        d[ a[e][b] ] = a[e][c]
      }
      return d
    },

    openPopup(data,header,criteria){
      this.open.popup = true;
      this.open.data = data;
      this.open.header = header;
      this.open.criteria = criteria;
      console.log(this.open)
    },
    remove(index, critaria){
      const position = this.investors[critaria].indexOf(index)
      this.investors[critaria].splice(position, 1);
      console.log(this.investors[critaria])
      // this.investors[critaria] = data;
    },

    


    

  },
  created() {
    // this.list.industries = this.parseArray(this.$_LIST_COMPANY_TYPE,'value','text')
    // this.list.countries =  this.parseArray(this.$_LIST_COUNTRIES,'code','name')

  },
};
</script>

<style scoped>
.selected{
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  margin:30px 10px;

}
/* .selected span{
  font-weight: bold;
} */
.selected .cancel{
  cursor: pointer;
  /* color: #ff0000; */
}

.po__container{
  height: 400px;
  margin: 0 auto;
  overflow: auto;
}
.investor__popup{
  display: block;
  width: 100%;

}
.investor__popup .form__group{
  /* margin-bottom: 10px; */
}
.form__header{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
}
.investment__group{
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 10px; */
  flex-wrap: wrap;
  border-bottom:1px solid #ccc;
}
.investment__group{
  width: 100%;
  margin-bottom: 10px;
}

.items{
  width: 100%;
  min-height: 50px;
  display: flex;
  /* justify-content: space-between; */
  /* margin-bottom: 10px; */
  flex-wrap: wrap;
}

</style>
